.bg-Odoo {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -10px -390px;
}

.bg-NetSuite {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -218px -390px;
}

.bg-QuickbooksOnline {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -10px -466px;
}

.bg-QuickbooksDesktop {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -426px -390px;
}

.bg-SageIntacct {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -218px -466px;
}

.bg-SAP {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -426px -466px;
}

.bg-Shopify {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -10px -542px;
}

.bg-ShopWare {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -218px -542px;
}

.bg-Walmart {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -10px -86px;
}

.bg-Stripe {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -10px -10px;
}

.bg-ZohoBook {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -10px -162px;
}

.bg-WooCommerce {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -218px -10px;
}

.bg-AuthorizeNet {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -10px -238px;
}

.bg-BigCommerce {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -218px -238px;
}

.bg-BrightPearl {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -10px -314px;
}

.bg-ClearBook {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -218px -314px;
}

.bg-Xero {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -218px -86px;
}

.bg-MsDynamic {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -426px -10px;
}

.bg-Ebay {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -426px -86px;
}

.bg-Acumatica {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -218px -162px;
}

.bg-FreshBooks {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -634px -162px;
}

.bg-Magento {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -426px -238px;
}

.bg-myob_removebg_preview {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -634px -238px;
}

.bg-BusinessCentral {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -426px -314px;
}

.bg-Salesforce {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -426px -542px;
}

.bg-ZohoCRM {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -634px -10px;
}

.bg-WaveAccount {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -634px -86px;
}

.bg-AmazonMarketPlace {
    width: 188px;
    height: 56px;
    background: url("../image/css_sprites_integrations.png") -634px -314px;
}

.grid-item-child {
    float: left;
    border: 1px solid #00001129;
    margin-left: 10px;
    padding: 10px;
    min-width: 300px;
    border-radius: 8px;
}

.software-grid {
    display: flex;
    justify-content: start;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.click-button {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.btn-primary {
    background-color: #007bff;
    color: #fff;
    text-align: center;
    min-width: 140px;
    font-size: 16px;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
}

.grid-item-child {
    cursor: pointer;
}

.active {
    border: 2px solid #0b78c2;
}

.connected {
    border: 2px solid green;
}

.error {
    border: 2px solid red;
}

.error-message {
    color: red;
    margin-top: 5px;
    font-size: 14px;
}



.icon {
    font-size: 16px;
}

.check-circle {
    width: 18px;
    height: 18px;
    border: 2px solid #52c41a;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
}

.check-circle .error {
    border: red;
}

.check-mark .error {
    border: red;
}

.check-mark {
    width: 4px;
    height: 7px;
    border-right: 2px solid #52c41a;
    border-bottom: 2px solid #52c41a;
    transform: rotate(45deg);
    position: absolute;
    bottom: 4px;
    left: 6px;
}

.oAuth {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 3%;
}

.oAuth h1 {
    font-size: 2.4rem;
    font-weight: 600;


}

.oAuth .info-text {
    font-size: 1.6rem;
    line-height: 1.8;
    margin-bottom: 1.5%;
}